import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import DonationForm from './DonationForm';

const promise = loadStripe(`${process.env.GATSBY_STRIPE_PUBLISHABLE_KEY}`);

export default function DonationWrapper(props) {
    return (
        <div>
            <Elements stripe={promise}>
                <DonationForm amount={props.amount} />
            </Elements>
        </div>
    )
}