import React, { useState, useRef, useEffect } from "react";
import lottie from "lottie-web";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Turnstile, { useTurnstile } from "react-turnstile";
import * as yup from "yup";
import {
  Radio,
  RadioGroup,
  VStack,
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  HStack,
  Flex,
  Text,
  Spinner,
  Link,
  Heading,
  Center,
} from "@chakra-ui/react";
import { BsCheck2Circle } from "react-icons/bs";
import { BsCurrencyDollar, BsAt } from "react-icons/bs";
import { VscWarning } from "react-icons/vsc";
import StripeLogo from "./stripe-logo.svg";
import Bubbles from "./bubbles";
import styled from "@emotion/styled";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email is required"),
  phone: yup.string().required("Phone is required"),
  address: yup.string().required("Address is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  zip: yup.string().required("Zip is required"),
});

const ConfettiBox = styled.div`
  width: ${(props) => props.width};
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default function DonationForm(props) {
  const confettiContainer1 = useRef(null);
  const confettiContainer = useRef(null);
  const confettiContainer2 = useRef(null);
  const confettiContainer3 = useRef(null);
  const confettiContainer4 = useRef(null);
  const confettiContainer5 = useRef(null);
  const confettiContainer6 = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      // target multiple animations by giving them a name
      name: "twentyFive",
      container: confettiContainer1.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: Bubbles,
    });
    lottie.loadAnimation({
      // target multiple animations by giving them a name
      name: "fifty",
      container: confettiContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: Bubbles,
    });
    lottie.loadAnimation({
      name: "oneHundred",
      container: confettiContainer2.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: Bubbles,
    });
    lottie.loadAnimation({
      name: "twoFifty",
      container: confettiContainer3.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: Bubbles,
    });
    lottie.loadAnimation({
      name: "fiveHundred",
      container: confettiContainer4.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: Bubbles,
    });
    lottie.loadAnimation({
      name: "oneThousand",
      container: confettiContainer5.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: Bubbles,
    });
    lottie.loadAnimation({
      name: "twentyFiveHundred",
      container: confettiContainer6.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: Bubbles,
    });
    return () => {
      lottie.destroy();
    };
  }, []);

  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [amount, setAmount] = useState();
  const [turnstileResponse, setTurnstileReponse] = useState(false);
  const turnstile = useTurnstile();

  const stripe = useStripe();
  const elements = useElements();

  const watchObject = watch();

  const errorStyles = {
    color: "#bf1650",
    fontSize: "1rem",
  };

  function RequiredSpan() {
    return <span style={{ color: "#bf1650" }}>*</span>;
  }

  // create a Ref to access our form element
  const formRef = useRef(null);

  const stripeStyle = {
    base: {
      color: "#134053",
      fontFamily: "Open Sans, sans-serif",
      fontSize: "1rem",
    },
    invalid: {
      color: "#bf1650",
      iconColor: "#bf1650",
    },
  };

  // Show any Stripe errors
  const handleChange = async (event) => {
    setError(event.error ? event.error.message : "");
  };

  const handle25 = () => {
    // hide the intial confetti animation
    confettiContainer.current.classList.add("show-lottie");
    lottie.stop("twentyFive");
    lottie.play("twentyFive");
  };
  const handle50 = () => {
    confettiContainer.current.classList.add("show-lottie");
    lottie.stop("fifty");
    lottie.play("fifty");
  };
  const handle100 = () => {
    confettiContainer2.current.classList.add("show-lottie");
    lottie.stop("oneHundred");
    lottie.play("oneHundred");
  };
  const handle250 = () => {
    confettiContainer3.current.classList.add("show-lottie");
    lottie.stop("twoFifty");
    lottie.play("twoFifty");
  };
  const handle500 = () => {
    confettiContainer4.current.classList.add("show-lottie");
    lottie.stop("fiveHundred");
    lottie.play("fiveHundred");
  };
  const handle1000 = () => {
    confettiContainer5.current.classList.add("show-lottie");
    lottie.stop("oneThousand");
    lottie.play("oneThousand");
  };
  const handle2500 = () => {
    confettiContainer6.current.classList.add("show-lottie");
    lottie.stop("twentyFiveHundred");
    lottie.play("twentyFiveHundred");
  };

  const addCommaToAmount = (amount) => {
    if (isNaN(amount)) {
      return "";
    } else {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  const FormSuccess = () => {
    return (
      <Box my="20">
        <VStack>
          <BsCheck2Circle size="4rem" />
          <Heading as="h2" textAlign="left" my="4">
            Thank you for your donation!
          </Heading>
        </VStack>
      </Box>
    );
  };

  const sendFormData = async (event) => {
    if (!formRef.current) {
      console.log("something went wrong with the ref");
    }

    const formData = new FormData(formRef.current);

    setProcessing(true);

    window
      .fetch("/.netlify/functions/validate-turnstile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          turnstileResponse: turnstileResponse,
          amount,
          receipt_email: watchObject.email,
          description: "Website Donation",
          metadata: {
            name: watchObject.name,
            email: watchObject.email,
            phone: watchObject.phone,
            address: watchObject.address,
            city: watchObject.city,
            state: watchObject.state,
            zip: watchObject.zip,
          },
        }),
      })
      .then((res) => {
        //return res.json();
        if (res.status === 200) {
          return res.json()
          .then((data) => {
            setClientSecret(data.clientSecret);
            return stripe
              .confirmCardPayment(data.clientSecret, {
                payment_method: {
                  card: elements.getElement(CardElement),
                },
              })
              .then((paymentResult) => {
                if (paymentResult.error) {
                  setError(`Payment failed: ${paymentResult.error.message}`);
                  setProcessing(false);
                  turnstile.reset();
                } else {
                  if (paymentResult.paymentIntent.status === "succeeded") {
                    turnstile.reset();
                    setError(null);
                    setSucceeded(true);
                    setProcessing(false);
                    reset();
                  }
                }
              });
          });
        } else {
          alert("Failed to validate CAPTCHA response. Please try again or contact us at fallon@downtowndriggs.org.");
          setProcessing(false);
          turnstile.reset();
        }
      })
      
  };

  return (
    <>
      {succeeded ? (
        <FormSuccess />
      ) : (
        <div>
          <Heading as="h2">Donate</Heading>
          <form
            id="donation-form"
            onSubmit={handleSubmit(sendFormData)}
            onChange={handleChange}
            ref={formRef}
          >
            <Box textAlign="left" mb="8">
              <FormControl>
                <FormLabel>
                  Name:
                  <RequiredSpan />
                </FormLabel>
                <Input
                  size="md"
                  type="text"
                  {...register("name")}
                  autoComplete="on"
                />

                {errors.name && (
                  <HStack spacing="5px" style={errorStyles}>
                    <VscWarning /> <Box>{errors.name?.message}</Box>
                  </HStack>
                )}
              </FormControl>
            </Box>

            <Flex w="100%" flexWrap="wrap">
              <Box
                textAlign="left"
                mb="8"
                w={{ base: "100%", lg: "50%" }}
                pr={{ base: 0, lg: 2 }}
              >
                <FormControl>
                  <FormLabel>
                    Email:
                    <RequiredSpan />
                  </FormLabel>
                  <Flex>
                    <Flex
                      alignItems="center"
                      borderLeftRadius="md"
                      border="1px solid #E2E8F0"
                      bg="gray.100"
                      px="4"
                      borderRight="none"
                    >
                      <span>
                        <BsAt fill="#4A5568" />
                      </span>
                    </Flex>
                    <Input
                      size="md"
                      type="text"
                      {...register("email")}
                      autoComplete="on"
                      borderLeftRadius="0"
                    />
                  </Flex>

                  {errors.email && (
                    <HStack spacing="5px" style={errorStyles}>
                      <VscWarning /> <Box>{errors.email?.message}</Box>
                    </HStack>
                  )}
                </FormControl>
              </Box>
              <Box
                textAlign="left"
                mb="8"
                w={{ base: "100%", lg: "50%" }}
                pl={{ base: 0, lg: 2 }}
              >
                <FormControl>
                  <FormLabel>
                    Phone:
                    <RequiredSpan />
                  </FormLabel>
                  <Input
                    size="md"
                    type="text"
                    {...register("phone")}
                    autoComplete="on"
                  />

                  {errors.phone && (
                    <HStack spacing="5px" style={errorStyles}>
                      <VscWarning /> <Box>{errors.phone?.message}</Box>
                    </HStack>
                  )}
                </FormControl>
              </Box>
            </Flex>

            <Box textAlign="left" mb="8">
              <FormControl>
                <FormLabel>
                  Street Address:
                  <RequiredSpan />
                </FormLabel>
                <Input
                  size="md"
                  type="text"
                  {...register("address")}
                  autoComplete="on"
                />

                {errors.address && (
                  <HStack spacing="5px" style={errorStyles}>
                    <VscWarning /> <Box>{errors.address?.message}</Box>
                  </HStack>
                )}
              </FormControl>
            </Box>

            <Flex w="100%" flexWrap="wrap">
              <Box
                textAlign="left"
                mb="8"
                w={{ base: "100%", lg: "33%" }}
                pr={{ base: 0, lg: 2 }}
              >
                <FormControl>
                  <FormLabel>
                    City:
                    <RequiredSpan />
                  </FormLabel>
                  <Input
                    size="md"
                    type="text"
                    {...register("city")}
                    autoComplete="on"
                  />

                  {errors.city && (
                    <HStack spacing="5px" style={errorStyles}>
                      <VscWarning /> <Box>{errors.city?.message}</Box>
                    </HStack>
                  )}
                </FormControl>
              </Box>
              <Box textAlign="left" mb="8" w={{ base: "100%", lg: "33%" }}>
                <FormControl>
                  <FormLabel>
                    State:
                    <RequiredSpan />
                  </FormLabel>
                  <Input
                    size="md"
                    type="text"
                    {...register("state")}
                    autoComplete="on"
                  />

                  {errors.state && (
                    <HStack spacing="5px" style={errorStyles}>
                      <VscWarning /> <Box>{errors.state?.message}</Box>
                    </HStack>
                  )}
                </FormControl>
              </Box>
              <Box
                textAlign="left"
                mb="8"
                w={{ base: "100%", lg: "33%" }}
                pl={{ base: 0, lg: 2 }}
              >
                <FormControl>
                  <FormLabel>
                    Zip:
                    <RequiredSpan />
                  </FormLabel>
                  <Input
                    size="md"
                    type="text"
                    {...register("zip")}
                    autoComplete="on"
                  />

                  {errors.zip && (
                    <HStack spacing="5px" style={errorStyles}>
                      <VscWarning /> <Box>{errors.zip?.message}</Box>
                    </HStack>
                  )}
                </FormControl>
              </Box>
            </Flex>
            <Box w="75%">
              <FormLabel>
                Donation Amount:
                <RequiredSpan />
              </FormLabel>
              <RadioGroup
                onChange={setAmount}
                value={amount}
                position="relative"
                zIndex="1"
              >
                <Flex w="100%" flexWrap="wrap" justifyContent="space-between">
                <Box position="relative" mb="2">
                    <ConfettiBox
                      ref={confettiContainer1}
                      width="100px"
                      className="hide-lottie"
                    />
                    <Radio value="25" onChange={() => handle25()}>
                      $25
                    </Radio>
                  </Box>
                  <Box position="relative" mb="2">
                    <ConfettiBox
                      ref={confettiContainer}
                      width="100px"
                      className="hide-lottie"
                    />
                    <Radio value="50" onChange={() => handle50()}>
                      $50
                    </Radio>
                  </Box>
                  <Box position="relative" mb="2">
                    <ConfettiBox
                      ref={confettiContainer2}
                      width="130px"
                      className="hide-lottie"
                    />
                    <Radio value="100" onChange={() => handle100()}>
                      $100
                    </Radio>
                  </Box>
                  <Box position="relative" mb="2">
                    <ConfettiBox
                      ref={confettiContainer3}
                      width="150px"
                      className="hide-lottie"
                    />
                    <Radio value="250" onChange={() => handle250()}>
                      $250
                    </Radio>
                  </Box>
                  <Box position="relative" mb="2">
                    <ConfettiBox
                      ref={confettiContainer4}
                      width="180px"
                      className="hide-lottie"
                    />
                    <Radio value="500" onChange={() => handle500()}>
                      $500
                    </Radio>
                  </Box>
                  <Box position="relative" mb="2">
                    <ConfettiBox
                      ref={confettiContainer5}
                      width="250px"
                      className="hide-lottie"
                    />
                    <Radio value="1000" onChange={() => handle1000()}>
                      $1000
                    </Radio>
                  </Box>
                  <Box position="relative" mb="2">
                    <ConfettiBox
                      ref={confettiContainer6}
                      width="250px"
                      className="hide-lottie"
                    />
                    <Radio value="2500" onChange={() => handle2500()}>
                      $2500
                    </Radio>
                  </Box>
                </Flex>
              </RadioGroup>
            </Box>
            <Box my="4">
              <Text textAlign="left">
                Contact <a href="mailto:fallon@downtowndriggs.org">fallon@downtowndriggs.org</a> if you would like to donate an amount not listed here.
              </Text>
            </Box>

            {/* <Box my="8" width="25%" position="relative">
              <FormControl>
                <FormLabel>Enter other amount:</FormLabel>
                <Flex>
                  <Flex
                    alignItems="center"
                    borderLeftRadius="md"
                    border="1px solid #E2E8F0"
                    bg="gray.100"
                    px="4"
                    borderRight="none"
                  >
                    <span>
                      <BsCurrencyDollar fill="#4A5568" />
                    </span>
                  </Flex>
                  <Input
                    type="number"
                    name="amount"
                    placeholder="Enter amount $"
                    {...register("amount")}
                    onChange={(event) =>
                      setAmount(parseInt(event.target.value))
                    }
                    borderLeftRadius="0"
                  />
                </Flex>
              </FormControl>
            </Box> */}

            <Box mb="2">
              <FormLabel>
                Credit Card Information:
                <RequiredSpan />
              </FormLabel>
              <CardElement options={{ style: stripeStyle }} />
            </Box>
            {error && (
              <div style={errorStyles} role="alert">
                <HStack spacing="5px">
                  <VscWarning />
                  <p className="card-error">{error}</p>
                </HStack>
              </div>
            )}
            <Flex w="100%" h="100%" alignItems="center">
              <Box mr="1">
                <Text fontSize="0.8rem" mb="0">
                  Payments securely processed by
                </Text>
              </Box>
              <Box>
                <Link
                  href="https://stripe.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={StripeLogo}
                    alt="Stripe logo"
                    style={{ width: "2.5rem" }}
                  />
                </Link>
              </Box>
            </Flex>
            <Center my="4">
              <Turnstile
                //sitekey="2x00000000000000000000AB" 
                sitekey={process.env.GATSBY_TURNSTILE_SITE_KEY}
                theme='light'
                onVerify={(token) => setTurnstileReponse(token)}
              />
            </Center>
            <Box my="6">
              <Button
                type="submit"
                textAlign="center"
                disabled={processing}
                isLoading={processing}
                size="lg"
              >
                {processing ? (
                  <Box
                    display="flex"
                    flexDirection="row"
                    height="100%"
                    alignItems="center"
                  >
                    <Box>Processing...</Box>
                    <Spinner />
                  </Box>
                ) : succeeded ? (
                  "Submitted!"
                ) : (
                  <>Donate ${addCommaToAmount(amount)}</>
                )}
              </Button>
              {error && (
                <div style={errorStyles} role="alert">
                  <HStack spacing="5px">
                    <VscWarning />
                    <p className="card-error">{error}</p>
                  </HStack>
                </div>
              )}
            </Box>
          </form>
        </div>
      )}
    </>
  );
}
